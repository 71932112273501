$font-family-sans-serif: Roboto, sans-serif;

$navbar-padding-y: 1rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-brand-margin-end: 1rem;

$btn-border-radius: .25em;
$btn-border-radius-lg: .25em;

$primary: #2B2D42;
$secondary: #008080;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@import 'bootstrap/scss/bootstrap';

.content {
  h2, h3, h4, h5, h6 {
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  img {
    @extend .img-fluid;
  }
}

:target {
  scroll-margin-top: 100px;
}

.testimonial {
  position: relative;
  padding-left: 4rem;
}

.testimonial::before {
  font-family: serif;
  display: block;
  content: "“";
  font-size: 6.5rem;
  position: absolute;
  left: 0;
  top: -2rem;
  color: #babdb6;
}

.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: shift-color($info, -92%);
  border-left: .25rem solid shift-color($info, -50%);
}

pre {
  background-color: shift-color($primary, 10%);
  padding: 1em;
  color: $light;
}
